import React from 'react';
import { useEffect, useState } from 'react';
import './ScrollDownButton.css';

interface ScrollDownButtonProps {
  messagesContainerRef: React.RefObject<HTMLDivElement | null>;
  onScrollDown: () => void;
}

const ScrollDownButton: React.FC<ScrollDownButtonProps> = ({
  messagesContainerRef,
  onScrollDown,
}) => {
  const [showButton, setShowButton] = useState(false);

  const checkScrollPosition = () => {
    const messagesContainer = messagesContainerRef.current;
    if (!messagesContainer) return;

    const visibleHeight = messagesContainer.clientHeight;
    const totalHeight = messagesContainer.scrollHeight;
    const scrollTop = messagesContainer.scrollTop;

    // Check if there's enough content to scroll
    const hasScrollableContent = totalHeight > visibleHeight;

    // Check if we're at the bottom
    const isAtBottom = totalHeight - (scrollTop + visibleHeight) <= 20;

    // Show button if we have scrollable content AND we're not at the bottom
    setShowButton(hasScrollableContent && !isAtBottom);
  };

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;

    // Add scroll event listener
    messagesContainer?.addEventListener('scroll', checkScrollPosition);

    // Add resize observer to detect changes in container size or content
    const resizeObserver = new ResizeObserver(() => {
      checkScrollPosition();
    });

    if (messagesContainer) {
      resizeObserver.observe(messagesContainer);
    }

    // Initial check
    checkScrollPosition();

    return () => {
      messagesContainer?.removeEventListener('scroll', checkScrollPosition);
      if (messagesContainer) {
        resizeObserver.unobserve(messagesContainer);
      }
      resizeObserver.disconnect();
    };
  }, [messagesContainerRef]);

  return (
    <button
      onClick={onScrollDown}
      title="Scroll to bottom"
      className={`scroll-down-btn ${showButton ? 'visible' : ''}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        aria-hidden="true"
      >
        <path d="m7 13 5 5 5-5" />
        <path d="m7 6 5 5 5-5" />
      </svg>
    </button>
  );
};

export default ScrollDownButton;
