import React from 'react';
import './WelcomePage.css';

interface WelcomePageProps {
  botName: string;
  botLogo?: string;
  initialQuestions: string[];
  onQuestionClick: (question: string) => void;
  onAskQuestionClick: () => void;
  theme: 'light' | 'dark';
  primaryColor: string;
  secondaryColor?: string;
}

const WelcomePage: React.FC<WelcomePageProps> = ({
  botName,
  botLogo,
  initialQuestions,
  onQuestionClick,
  onAskQuestionClick,
  theme,
  primaryColor,
  secondaryColor,
}) => {
  const hasInitialQuestions = initialQuestions && initialQuestions.length > 0;

  // Log initial questions received
  console.log('📝 WelcomePage received initialQuestions:', {
    count: initialQuestions?.length,
    hasQuestions: hasInitialQuestions,
    questions: initialQuestions,
  });

  return (
    <div className={`welcome-page ${!hasInitialQuestions ? 'welcome-no-suggestions' : ''}`}>
      <div className="welcome-content">
        <div className="welcome-header">
          {botLogo && (
            <div className="welcome-bot-logo">
              <img
                src={botLogo}
                alt={`${botName} logo`}
                onError={e => {
                  (e.target as HTMLImageElement).style.display = 'none';
                }}
              />
            </div>
          )}
          <h2 className="welcome-title">Hello, how can I help you today?</h2>
          <p className="welcome-subtitle">
            I'm {botName}, your dedicated support specialist.{' '}
            {hasInitialQuestions
              ? 'Feel free to ask me anything or choose from the suggestions below.'
              : 'Feel free to ask me anything!'}
          </p>
        </div>

        {hasInitialQuestions && (
          <div className="welcome-suggestions">
            <h3 className="suggestions-title">Suggested questions</h3>
            <div className="suggestions-list">
              {initialQuestions.map((question, index) => (
                <button
                  key={index}
                  className="suggestion-button"
                  onClick={() => onQuestionClick(question)}
                  style={
                    {
                      '--button-hover-color': primaryColor,
                    } as React.CSSProperties
                  }
                >
                  <span className="suggestion-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
                  </span>
                  <span className="suggestion-text">{question}</span>
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="welcome-ask-container">
          <button
            className="welcome-ask-button"
            onClick={onAskQuestionClick}
            style={{
              backgroundColor: primaryColor,
            }}
          >
            <span className="ask-button-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                <path d="M12 7v6" />
                <path d="M9 10h6" />
              </svg>
            </span>
            <span className="ask-button-text">Ask a question</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
