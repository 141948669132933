// Theme color configurations
export interface ThemeColors {
  primaryColor: string;
  secondaryColor: string;
  textLight: string;
  textDark: string;
  inputText: string;
  bgLight: string;
  bgGray: string;
  bgInput: string;
  borderLight: string;
  shadowColor: string;
  shadowPrimary: string;
  overlayLight: string;
  overlayMedium: string;
  overlayDark: string;
  codeBg: string;
  codeText: string;
  codeBgDark: string;
  typingDot: string;
}

export type ThemeMode = 'light' | 'dark';

// Updated interface to match API response
export interface AIAgentCustomFields {
  // Basic settings
  assistantName: string;
  position: 'bottom-right' | 'bottom-left';
  initialMessage: string;
  botLogo?: string;

  // Theme settings
  lightTheme?: ThemeColors;
  darkTheme?: ThemeColors;
  initialQuestions?: string[]; // Array of initial questions

  // Legacy field for backward compatibility
  primaryColor?: string;
}

// Default primary and secondary colors
const defaultPrimary = '#007bff';
const defaultSecondary = '#333333';

// Theme color palettes - keeping these for fallback and default initialization
export const themeColors: Record<
  ThemeMode,
  (primaryColor?: string, secondaryColor?: string) => ThemeColors
> = {
  light: (primaryColor = defaultPrimary, secondaryColor = defaultSecondary) => ({
    primaryColor,
    secondaryColor,
    textLight: '#ffffff',
    textDark: '#333333',
    inputText: '#333333',
    bgLight: '#ffffff',
    bgGray: '#f8f9fa',
    bgInput: '#f0f0f0',
    borderLight: '#e8e8e8',
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowPrimary: 'rgba(99, 102, 241, 0.15)',
    overlayLight: 'rgba(255, 255, 255, 0.1)',
    overlayMedium: 'rgba(255, 255, 255, 0.15)',
    overlayDark: 'rgba(255, 255, 255, 0.2)',
    codeBg: '#f1f1f1',
    codeText: '#333333',
    codeBgDark: 'rgba(255, 255, 255, 0.15)',
    typingDot: '#90909090',
  }),
  dark: (primaryColor = defaultPrimary, secondaryColor = defaultSecondary) => ({
    primaryColor,
    secondaryColor,
    textLight: '#f8f9fa',
    textDark: '#e0e0e0',
    inputText: '#ffffff',
    bgLight: '#2d2d2d',
    bgGray: '#383838',
    bgInput: '#3a3a3a',
    borderLight: '#555555',
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowPrimary: 'rgba(99, 102, 241, 0.25)',
    overlayLight: 'rgba(0, 0, 0, 0.1)',
    overlayMedium: 'rgba(0, 0, 0, 0.15)',
    overlayDark: 'rgba(0, 0, 0, 0.2)',
    codeBg: '#383838',
    codeText: '#e0e0e0',
    codeBgDark: 'rgba(0, 0, 0, 0.2)',
    typingDot: '#aaaaaa90',
  }),
};

// Helper function to convert ThemeColors to CSS properties object
export const themeToCssVars = (theme: ThemeColors): React.CSSProperties => {
  return {
    '--primary-color': theme.primaryColor,
    '--secondary-color': theme.secondaryColor,
    '--text-light': theme.textLight,
    '--text-dark': theme.textDark,
    '--input-text': theme.inputText,
    '--bg-light': theme.bgLight,
    '--bg-gray': theme.bgGray,
    '--bg-input': theme.bgInput,
    '--border-light': theme.borderLight,
    '--shadow-color': theme.shadowColor,
    '--shadow-primary': theme.shadowPrimary,
    '--overlay-light': theme.overlayLight,
    '--overlay-medium': theme.overlayMedium,
    '--overlay-dark': theme.overlayDark,
    '--code-bg': theme.codeBg,
    '--code-text': theme.codeText,
    '--code-bg-dark': theme.codeBgDark,
    '--typing-dot': theme.typingDot,
  } as React.CSSProperties;
};

// Get theme from API response or generate default
export const getThemeFromResponse = (
  customFields: AIAgentCustomFields | undefined,
  mode: ThemeMode
): ThemeColors => {
  if (!customFields) {
    return themeColors[mode]();
  }

  // If API provides explicit theme objects, use them
  if (mode === 'light' && customFields.lightTheme) {
    return customFields.lightTheme;
  }

  if (mode === 'dark' && customFields.darkTheme) {
    return customFields.darkTheme;
  }

  // Fallback to generating theme from primaryColor for backward compatibility
  return themeColors[mode](customFields.primaryColor);
};

// Detect system theme preference
export const getSystemTheme = (): ThemeMode => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }
  return 'light'; // Default fallback
};
