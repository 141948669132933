import React, { useState, useRef, useEffect, useCallback } from 'react';
import './AIChatWidget.css';
import './animations.css';
// import { mockChatMessages } from '../../mock-data';
import ScrollDownButton from '../ScrollDownButton/ScrollDownButton';
import { stripPatternFromString, parseMarkdown } from '../../lib/utils';
import { formatRelativeTime, updateAllTimestamps } from '../../lib/timeUtils';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import ThemeToggle from './themes/ThemeToggle';
import { ThemeMode, themeColors, themeToCssVars, getSystemTheme } from './themes/themeConfig';
import WelcomePage from '../WelcomePage/WelcomePage';

// Props interface for the AIChatWidget component
export interface AIChatWidgetProps {
  botName?: string; // Display name of the chatbot
  botLogo?: string; // URL or base64 image for bot logo/mascot
  primaryColor?: string; // Primary color theme for the widget
  secondaryColor?: string; // Secondary color for buttons and accents
  position?: 'left' | 'right'; // Position of the widget on the screen
  initialMessages?: string[]; // Suggested initial messages/questions from API
  appId: string; // Only required prop from client
  token?: string; // Optional authentication token
  apiBaseUrl: string; // Base URL for API requests
  theme?: ThemeMode; // Optional theme prop
  onThemeChange?: (theme: ThemeMode) => void; // Optional callback for theme changes
  useShadowDom?: boolean; // Whether the component is rendered within Shadow DOM
}

// Interface for chat messages
interface Message {
  text: string; // Content of the message
  type: 'bot' | 'user'; // Sender type to differentiate styling
  timestamp: Date; // Time when message was sent
  threadId?: string | null; // Allow null type for threadId
}

// Interface for local storage data
interface ChatStorage {
  threadId: string | null;
  messages: StorageMessage[];
}

// Message format for storage (using string for timestamp)
interface StorageMessage {
  text: string;
  type: 'bot' | 'user';
  timestamp: string;
  threadId?: string | null;
}

// Constant for localStorage key
const MESSAGES_KEY = 'ai_chat_messages';

/**
 * Helper function to save messages to localStorage
 */
const saveMessagesToLocalStorage = (threadId: string | null, messages: Message[]): void => {
  if (!threadId) return;

  // Convert Messages to StorageMessages (Date -> string)
  const storageMessages: StorageMessage[] = messages.map(message => ({
    ...message,
    timestamp: message.timestamp.toISOString(),
  }));

  const chatStorage: ChatStorage = {
    threadId,
    messages: storageMessages,
  };

  try {
    localStorage.setItem(MESSAGES_KEY, JSON.stringify(chatStorage));
  } catch (error) {
    console.error('Error saving messages to localStorage:', error);
  }
};

/**
 * Helper function to load messages from localStorage
 */
const loadMessagesFromLocalStorage = (threadId: string | null): Message[] => {
  if (!threadId) return [];

  try {
    const storageData = localStorage.getItem(MESSAGES_KEY);
    if (!storageData) return [];

    const chatStorage: ChatStorage = JSON.parse(storageData);

    // Only use messages if threadId matches
    if (chatStorage.threadId !== threadId) return [];

    // Convert StorageMessages to Messages (string -> Date)
    return chatStorage.messages.map(message => ({
      ...message,
      timestamp: new Date(message.timestamp),
    }));
  } catch (error) {
    console.error('Error loading messages from localStorage:', error);
    return [];
  }
};

/**
 * Helper function to get threadId from localStorage
 */
const getThreadIdFromLocalStorage = (): string | null => {
  try {
    const storageData = localStorage.getItem(MESSAGES_KEY);
    if (!storageData) return null;

    const chatStorage: ChatStorage = JSON.parse(storageData);
    return chatStorage.threadId;
  } catch (error) {
    console.error('Error retrieving threadId from localStorage:', error);
    return null;
  }
};

/**
 * Helper function to clear messages from localStorage
 */
const clearMessagesFromLocalStorage = (): void => {
  try {
    localStorage.removeItem(MESSAGES_KEY);
  } catch (error) {
    console.error('Error clearing messages from localStorage:', error);
  }
};

/**
 * Helper function to check if there's an existing conversation
 */
const hasExistingConversation = (): boolean => {
  try {
    const storageData = localStorage.getItem(MESSAGES_KEY);
    if (!storageData) return false;

    const chatStorage: ChatStorage = JSON.parse(storageData);
    return chatStorage.messages && chatStorage.messages.length > 0;
  } catch (error) {
    console.error('Error checking for existing conversation:', error);
    return false;
  }
};

// Add interface for message group
interface MessageGroup {
  type: 'bot' | 'user';
  messages: Message[];
  timestamp: Date;
}

/**
 * AIChatWidget Component
 * A floating chat widget that can be integrated into any website
 */
const AIChatWidget: React.FC<AIChatWidgetProps> = ({
  botName = 'AI Assistant',
  botLogo,
  primaryColor = '#007bff',
  secondaryColor = '#007bff', // Default secondary color
  position = 'right',
  initialMessages = [], // Default to empty array
  appId,
  token,
  apiBaseUrl,
  theme: initialTheme,
  onThemeChange,
  useShadowDom,
}) => {
  // Log initial props received
  console.log('🚀 AIChatWidget initialized with:', {
    botName,
    primaryColor,
    secondaryColor,
    position,
    initialMessagesCount: initialMessages?.length,
    initialMessages,
    appId,
    apiBaseUrl,
  });

  // Get thread_id from messages storage
  const existingThreadId = getThreadIdFromLocalStorage();

  // State management
  const [isOpen, setIsOpen] = useState(false); // Controls chat window visibility
  const [inputValue, setInputValue] = useState(''); // User input field value
  const [threadId, setThreadId] = useState<string | null>(existingThreadId);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAITyping, setIsAITyping] = useState(false);
  const [isWide, setIsWide] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showClearConfirm, setShowClearConfirm] = useState(false);
  const [copiedMessageId, setCopiedMessageId] = useState<string | null>(null);
  const [showWelcomePage, setShowWelcomePage] = useState<boolean>(!hasExistingConversation());
  // Theme state with system preference as default
  const [theme, setTheme] = useState<ThemeMode>(() => initialTheme || getSystemTheme());
  const [enableThemeToggle, setEnableThemeToggle] = useState(false);
  const [enableAttribution, setEnableAttribution] = useState(true);

  // Initialize messages from localStorage or with welcome message
  const initialMessagesFromStorage = existingThreadId
    ? loadMessagesFromLocalStorage(existingThreadId)
    : [];

  // If we have no stored messages but have a threadId, show welcome back message
  if (existingThreadId && initialMessagesFromStorage.length === 0) {
    initialMessagesFromStorage.push({
      text: 'Welcome back! Continuing our previous conversation...',
      type: 'bot',
      timestamp: new Date(),
      threadId: existingThreadId,
    });
  }

  const [messages, setMessages] = useState<Message[]>(initialMessagesFromStorage);

  // Refs for DOM manipulation
  const chatButtonRef = useRef<HTMLButtonElement>(null); // Reference to chat toggle button
  const messagesEndRef = useRef<HTMLDivElement>(null); // Reference to messages end div for scrolling
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null); // Reference to input field for auto-focus

  /**
   * Scrolls the chat window to the latest message
   * Uses smooth scrolling for better UX
   */
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Auto-scroll when new messages are added
  useEffect(() => {
    scrollToBottom();

    // After scrolling and rendering, trigger a scroll event to update the ScrollDownButton visibility
    setTimeout(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.dispatchEvent(new Event('scroll'));
      }
    }, 100);
  }, [messages]);

  // Initialize component and set initialized flag
  useEffect(() => {
    setIsInitialized(true);

    // Set welcome page visibility based on existing conversation
    const shouldShowWelcomePage = !hasExistingConversation();
    console.log('🏠 Welcome page visibility check:', {
      shouldShowWelcomePage,
      hasInitialMessages: initialMessages && initialMessages.length > 0,
    });

    setShowWelcomePage(shouldShowWelcomePage);
  }, [initialMessages]);

  // Save messages to localStorage when they change
  useEffect(() => {
    if (threadId && messages.length > 0) {
      saveMessagesToLocalStorage(threadId, messages);

      // If we have messages, we're in a conversation, so hide welcome page
      if (messages.length > 0 && showWelcomePage) {
        setShowWelcomePage(false);
      }
    }
  }, [messages, threadId, showWelcomePage]);

  // Add effect to update CSS variables when colors change
  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', primaryColor);
    document.documentElement.style.setProperty('--secondary-color', secondaryColor);
  }, [primaryColor, secondaryColor]);

  // Add a timer for updating timestamps
  useEffect(() => {
    // Update timestamps every minute
    const timestampInterval = setInterval(() => {
      updateAllTimestamps();
    }, 60000);

    return () => clearInterval(timestampInterval);
  }, []);

  // Function to toggle theme
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    if (onThemeChange) {
      onThemeChange(newTheme);
    }
  };

  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e: MediaQueryListEvent) => {
      if (initialTheme === undefined) {
        // Only update if theme not explicitly set
        setTheme(e.matches ? 'dark' : 'light');
      }
    };

    // Modern browsers
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleChange);
    }

    return () => {
      // Clean up
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleChange);
      }
    };
  }, [initialTheme]);

  // Get theme colors based on current theme and primary/secondary colors
  const currentThemeColors = themeColors[theme](primaryColor, secondaryColor);
  const themeStyle = themeToCssVars(currentThemeColors);

  /**
   * Handles opening/closing the chat window
   * Prevents default button behavior and event bubbling
   */
  const toggleChat = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 300); // Match this with the CSS transition duration
    } else {
      setIsOpen(true);

      // Focus input field after opening if welcome page is not shown
      setTimeout(() => {
        if (!showWelcomePage && inputRef.current) {
          inputRef.current.focus();
        }
      }, 100); // Allow time for the chat window to open
    }

    // Scroll to chat button when opening
    if (!isOpen && chatButtonRef.current) {
      chatButtonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  };

  /**
   * Updates input field value as user types
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  /**
   * Handler for sending a message programmatically
   */
  const sendMessage = useCallback(
    (text: string) => {
      // Hide welcome page if it's currently shown
      if (showWelcomePage) {
        setShowWelcomePage(false);
      }

      const userMessage: Message = {
        text,
        type: 'user',
        timestamp: new Date(),
        threadId,
      };
      setMessages(prev => [...prev, userMessage]);
      setIsAITyping(true);

      // Send to API
      fetch(`${apiBaseUrl}/api/ai/assistant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-App-Id': appId,
          ...(token && { Authorization: `Bearer ${token}` }),
        },
        body: JSON.stringify({
          message: text,
          threadId: threadId || undefined,
        }),
      })
        .then(response => {
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          return response.json();
        })
        .then(data => {
          if (data.threadId) {
            setThreadId(data.threadId);
          }

          setIsAITyping(false);
          const botMessage: Message = {
            text: data.response,
            type: 'bot',
            timestamp: new Date(),
            threadId: data.threadId,
          };
          setMessages(prev => [...prev, botMessage]);
        })
        .catch(error => {
          setIsAITyping(false);
          console.error('Error sending message:', error);
          const errorMessage: Message = {
            text: 'Sorry, I encountered an error. Please try again.',
            type: 'bot',
            timestamp: new Date(),
            threadId,
          };
          setMessages(prev => [...prev, errorMessage]);
        });
    },
    [apiBaseUrl, appId, threadId, token, showWelcomePage]
  );

  /**
   * Handles message submission from input form
   */
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    sendMessage(inputValue);
    setInputValue('');
  };

  /**
   * Handler for the "Ask Question" button in welcome page
   */
  const handleAskQuestionClick = () => {
    setShowWelcomePage(false);

    // Focus the input field after the DOM updates
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  };

  /**
   * Handler for clicking on a suggested question in welcome page
   */
  const handleSuggestedQuestionClick = (question: string) => {
    sendMessage(question);

    // Focus the input field after sending the message
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  };

  // Add method to clear conversation
  const clearConversation = () => {
    setShowClearConfirm(true);
  };

  const handleConfirmClear = () => {
    // Clear messages from state
    setMessages([]);

    // Clear storage and reset threadId
    setThreadId(null);
    clearMessagesFromLocalStorage();
    setShowClearConfirm(false);

    // Show welcome page again
    setShowWelcomePage(true);

    // Force scroll check after clearing conversation
    setTimeout(() => {
      if (messagesContainerRef.current) {
        // Trigger scroll event to update the ScrollDownButton visibility
        messagesContainerRef.current.dispatchEvent(new Event('scroll'));
      }
    }, 100);
  };

  const handleCancelClear = () => {
    setShowClearConfirm(false);
  };

  const toggleWidth = () => setIsWide(!isWide);

  // Function to group messages by sender
  const groupMessages = (messages: Message[]): MessageGroup[] => {
    if (!messages.length) return [];

    const sortedMessages = [...messages].sort(
      (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
    );

    const groups: MessageGroup[] = [];
    let currentGroup: MessageGroup | null = null;

    sortedMessages.forEach(message => {
      // If this is the first message or the sender changed, create a new group
      if (!currentGroup || currentGroup.type !== message.type) {
        if (currentGroup) {
          groups.push(currentGroup);
        }

        currentGroup = {
          type: message.type,
          messages: [message],
          timestamp: message.timestamp,
        };
      } else {
        // Add to existing group
        currentGroup.messages.push(message);
        // Update group timestamp to be the latest message time
        if (message.timestamp.getTime() > currentGroup.timestamp.getTime()) {
          currentGroup.timestamp = message.timestamp;
        }
      }
    });

    // Add the last group if it exists
    if (currentGroup) {
      groups.push(currentGroup);
    }

    return groups;
  };

  /**
   * Copy message text to clipboard
   */
  const copyToClipboard = (text: string, messageId: string) => {
    // Use the modern Clipboard API
    navigator.clipboard
      .writeText(stripPatternFromString(text))
      .then(() => {
        // Show copied feedback
        setCopiedMessageId(messageId);

        // Reset feedback after 2 seconds
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div className={`ai-chat-widget-container ${position} theme-${theme}`} style={themeStyle}>
      {/* Chat Window */}
      {isOpen && (
        <div className={`chat-window ${isWide ? 'wide' : ''} ${isClosing ? 'closing' : ''}`}>
          {/* Chat Header */}
          <div className="chat-header">
            <div className="header-title">
              {botLogo && (
                <div className="bot-logo">
                  <img
                    src={botLogo}
                    alt={`${botName} logo`}
                    onError={e => {
                      // Hide image on error
                      (e.target as HTMLImageElement).style.display = 'none';
                    }}
                  />
                </div>
              )}
              <h3>{botName}</h3>
            </div>
            <div className="header-buttons-grp">
              {threadId && (
                <button
                  onClick={clearConversation}
                  className="header-buttons"
                  title="Clear conversation"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M3 2v6h6" />
                    <path d="M21 12A9 9 0 0 0 6 5.3L3 8" />
                    <path d="M21 22v-6h-6" />
                    <path d="M3 12a9 9 0 0 0 15 6.7l3-2.7" />
                    <circle cx="12" cy="12" r="1" />
                  </svg>
                </button>
              )}

              {/* Add Theme Toggle button */}
              {enableThemeToggle && <ThemeToggle theme={theme} toggleTheme={toggleTheme} />}

              <button onClick={toggleWidth} className="header-buttons" title="Toggle width">
                {isWide ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M8 3v3a2 2 0 0 1-2 2H3" />
                    <path d="M21 8h-3a2 2 0 0 1-2-2V3" />
                    <path d="M3 16h3a2 2 0 0 1 2 2v3" />
                    <path d="M16 21v-3a2 2 0 0 1 2-2h3" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M8 3H5a2 2 0 0 0-2 2v3" />
                    <path d="M21 8V5a2 2 0 0 0-2-2h-3" />
                    <path d="M3 16v3a2 2 0 0 0 2 2h3" />
                    <path d="M16 21h3a2 2 0 0 0 2-2v-3" />
                  </svg>
                )}
              </button>
              <button onClick={toggleChat} className="header-buttons" aria-label="Close chat">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </button>
            </div>
          </div>

          {/* Conditional Content: Welcome Page or Chat Messages */}
          {showWelcomePage ? (
            <WelcomePage
              botName={botName}
              botLogo={botLogo}
              initialQuestions={initialMessages}
              onQuestionClick={handleSuggestedQuestionClick}
              onAskQuestionClick={handleAskQuestionClick}
              theme={theme}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
            />
          ) : (
            <>
              {/* Messages Container */}
              <div className="chat-messages" ref={messagesContainerRef}>
                {/* Group messages by sender and render groups */}
                {groupMessages(messages).map((group, groupIndex) => (
                  <div key={groupIndex} className={`message-group ${group.type}`}>
                    {group.messages.map((message, messageIndex) => {
                      // Generate a unique ID for each message for copy feedback
                      const messageId = `msg-${groupIndex}-${messageIndex}`;

                      return (
                        <div key={messageId} className={`message ${message.type} message-enter`}>
                          {/* Message content */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: parseMarkdown(stripPatternFromString(message.text)),
                            }}
                          />

                          {/* Footer with timestamp and actions in one row */}
                          <div className="message-footer">
                            <span
                              className="message-timestamp"
                              data-timestamp={message.timestamp.toISOString()}
                            >
                              {formatRelativeTime(message.timestamp)}
                            </span>

                            {message.type === 'bot' && (
                              <div className="message-actions">
                                <button
                                  className={`action-button copy-button ${
                                    copiedMessageId === messageId ? 'copied' : ''
                                  }`}
                                  onClick={() => copyToClipboard(message.text, messageId)}
                                  title="Copy to clipboard"
                                >
                                  {copiedMessageId === messageId ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                      <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1"></path>
                                    </svg>
                                  )}
                                </button>
                                {/* Space for future action buttons like thumbs up/down */}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}

                {/* Add this after the existing message groups but before the typing indicator */}
                {messages.length === 0 && !showWelcomePage && (
                  <div className="message-group bot">
                    <div className="message bot message-enter">
                      <div>
                        <h3>Hi there, welcome 👋</h3>
                        <p>
                          You're chatting with an AI Agent! I'm not your average chatbot—I can do
                          way more. Just tell me what's on your mind, and I'll do my best to help
                          you out quickly.
                        </p>
                      </div>
                      <div className="message-footer">
                        <span
                          className="message-timestamp"
                          data-timestamp={new Date().toISOString()}
                        >
                          {formatRelativeTime(new Date())}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {/* Enhanced typing indicator */}
                {isAITyping && (
                  <div className="message bot typing-indicator message-enter">
                    <div className="typing-bubble-container">
                      <div className="typing-bubble"></div>
                      <div className="typing-bubble"></div>
                      <div className="typing-bubble"></div>
                    </div>
                  </div>
                )}

                <div ref={messagesEndRef} />
              </div>

              {/* Scroll Down Button - now outside chat-messages but inside chat window */}
              <ScrollDownButton
                messagesContainerRef={messagesContainerRef}
                onScrollDown={scrollToBottom}
              />

              {/* Input Form - Only show when welcome page is not displayed */}
              <form className="chat-input" onSubmit={handleSubmit}>
                <input
                  ref={inputRef}
                  type="text"
                  placeholder={isAITyping ? 'Please wait...' : 'Type a message'}
                  value={inputValue}
                  onChange={handleInputChange}
                  disabled={isAITyping}
                  className={isAITyping ? 'disabled' : ''}
                />
                <button
                  type="submit"
                  aria-label="Send message"
                  disabled={isAITyping || !inputValue.trim()}
                  className={isAITyping ? 'disabled' : ''}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M3.714 3.048a.498.498 0 0 0-.683.627l2.843 7.627a2 2 0 0 1 0 1.396l-2.842 7.627a.498.498 0 0 0 .682.627l18-8.5a.5.5 0 0 0 0-.904z" />
                    <path d="M6 12h16" />
                  </svg>
                </button>
              </form>
            </>
          )}

          {/* Attribution Footer */}
          {enableAttribution && (
            <div className="chat-attribution">
              <a href="https://www.askinfosec.tech" target="_blank" rel="noopener noreferrer">
                Powered by AskInfosec
              </a>
            </div>
          )}

          {showClearConfirm && (
            <ConfirmDialog
              title="Start new chat"
              message="Are you sure you want to start a new chat and end the current chat?"
              onConfirm={handleConfirmClear}
              onCancel={handleCancelClear}
            />
          )}
        </div>
      )}
      {/* Toggle Button */}
      <button ref={chatButtonRef} className="chat-toggle" onClick={toggleChat}>
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        )}
      </button>
    </div>
  );
};

export default AIChatWidget;
