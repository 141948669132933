/**
 * Utility functions for working with Shadow DOM in the chat widget
 */

// CSS strings to be embedded in Shadow DOM
import aiChatWidgetCss from '../../components/AIChatWidget/AIChatWidget.css?raw';
import animationsCss from '../../components/AIChatWidget/animations.css?raw';
import scrollDownButtonCss from '../../components/ScrollDownButton/ScrollDownButton.css?raw';
import confirmDialogCss from '../../components/ConfirmDialog/ConfirmDialog.css?raw';
import welcomePageCss from '../../components/WelcomePage/WelcomePage.css?raw';

/**
 * Injects CSS into a Shadow DOM root
 * Combines all CSS into a single style element for better performance
 * @param shadowRoot The Shadow DOM root to inject CSS into
 */
export const injectCssToShadowDom = (shadowRoot: ShadowRoot): void => {
  try {
    const styleElement = document.createElement('style');

    // First, import all component CSS
    // We'll override their styles afterwards
    const componentCss = `
      ${aiChatWidgetCss}
      ${animationsCss}
      ${scrollDownButtonCss}
      ${confirmDialogCss}
      ${welcomePageCss}
    `;

    // Then add our custom CSS with overrides
    const customCss = `
      /* Complete Shadow DOM isolation */
      :host {
        all: initial !important;
        display: block !important;
        font-size: 16px !important;
      }
      
      /* Force root font size - essential baseline */
      .ai-chat-widget-root {
        font-size: 16px !important;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        line-height: 1.5 !important;
        color: #333 !important;
        box-sizing: border-box !important;
        margin: 0 !important;
        padding: 0 !important;
      }
      
      /* Reset all elements */
      .ai-chat-widget-root * {
        box-sizing: border-box !important;
        font-family: inherit !important;
        line-height: inherit !important;
      }
      
      /* Force EXPLICIT font sizes for ALL elements */
      /* This ensures no element can inherit from outside the Shadow DOM */
      
      /* Default text size for most elements */
      .ai-chat-widget-root div,
      .ai-chat-widget-root span,
      .ai-chat-widget-root p,
      .ai-chat-widget-root input,
      .ai-chat-widget-root button,
      .ai-chat-widget-root textarea,
      .ai-chat-widget-root li,
      .ai-chat-widget-root a {
        font-size: 16px !important;
      }
      
      /* Headings */
      .ai-chat-widget-root h1 { font-size: 32px !important; font-weight: 600 !important; }
      .ai-chat-widget-root h2 { font-size: 28px !important; font-weight: 600 !important; }
      .ai-chat-widget-root h3 { font-size: 20px !important; font-weight: 600 !important; }
      .ai-chat-widget-root h4 { font-size: 18px !important; font-weight: 600 !important; }
      .ai-chat-widget-root h5 { font-size: 16px !important; font-weight: 600 !important; }
      .ai-chat-widget-root h6 { font-size: 14px !important; font-weight: 600 !important; }
      
      /* Specific elements with pixel values instead of relative units */
      .ai-chat-widget-root .welcome-title { font-size: 32px !important; font-weight: 700 !important; }
      .ai-chat-widget-root .welcome-subtitle { font-size: 16px !important; }
      .ai-chat-widget-root .suggestions-title { font-size: 20px !important; font-weight: 600 !important; }
      .ai-chat-widget-root .suggestion-text { font-size: 16px !important; }
      .ai-chat-widget-root .ask-button-text { font-size: 16px !important; }
      
      /* Message texts */
      .ai-chat-widget-root .message,
      .ai-chat-widget-root .message p,
      .ai-chat-widget-root .message div,
      .ai-chat-widget-root .message span {
        font-size: 16px !important;
      }
      
      /* Bot name in header */
      .ai-chat-widget-root .chat-header .bot-name,
      .ai-chat-widget-root .bot-name {
        font-size: 20px !important; 
        font-weight: 600 !important;
      }
      
      /* Attribution footer - small text */
      .ai-chat-widget-root .chat-attribution,
      .ai-chat-widget-root .powered-by,
      .ai-chat-widget-root [class*="footer"],
      .ai-chat-widget-root [class*="attribution"] {
        font-size: 10px !important;
        opacity: 0.8 !important;
      }
      
      /* Status indicator size */
      .ai-chat-widget-root .status-indicator {
        width: 10px !important;
        height: 10px !important;
        border-radius: 5px !important;
      }
    `;

    // Combine the CSS - load component CSS first, then apply our overrides
    const combinedCss = componentCss + customCss;

    styleElement.textContent = combinedCss;
    shadowRoot.appendChild(styleElement);

    console.log('✅ CSS injected into Shadow DOM');
  } catch (error) {
    console.error('Error injecting CSS into Shadow DOM:', error);
  }
};

/**
 * Creates a Shadow DOM root for the chat widget
 * @param container The container element to attach Shadow DOM to
 * @returns The element to render React into
 */
export const createShadowRoot = (container: HTMLElement): HTMLDivElement => {
  // Create shadow DOM
  const shadowRoot = container.attachShadow({ mode: 'open' });

  // Create widget root element
  const widgetRoot = document.createElement('div');
  widgetRoot.className = 'ai-chat-widget-root';

  // Inject CSS
  injectCssToShadowDom(shadowRoot);

  // Append widget root to shadow DOM
  shadowRoot.appendChild(widgetRoot);

  return widgetRoot;
};

/**
 * Determines if the current environment supports Shadow DOM
 * @returns True if Shadow DOM is supported
 */
export const isShadowDomSupported = (): boolean => {
  return typeof HTMLElement.prototype.attachShadow === 'function';
};
