export const stripPatternFromString = (input: string) => {
  // Remove only source citations like [4:5†source], 【16:1†source】【16:2†source】
  return (
    input
      // Remove consecutive source citations
      .replace(/(?:【\d+:\d+†source】)+/g, '')
      // Remove single source citations with various bracket styles
      .replace(/[【\[]?\d+:\d+†source[】\]]?/g, '')
      // Clean up any extra horizontal spaces (but preserve newlines)
      .replace(/[^\S\n]+/g, ' ')
      .trim()
  );
};

// Keep stripAllMetadata for other use cases if needed
export const stripAllMetadata = (input: string) => {
  return input.replace(/[【\[]?\d+:\d+†source[】\]]?/g, '').trim();
};

/**
 * Enhanced markdown parser with more markdown features
 */
export const parseMarkdown = (text: string) => {
  // First, escape any existing HTML to prevent XSS
  text = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // Now unhide any HTML tags that were actually part of the message structure (from backend)
  // This assumes backend sends safe HTML that we want to preserve
  text = text.replace(
    /&lt;(\/?(br|p|div|span|h[1-6]|ul|ol|li|strong|em|a|code|pre)(\s[^>]*)?\/?)&gt;/gi,
    '<$1>'
  );

  // Handle headers - must come before bold/italic to avoid conflicts
  text = text.replace(/^### (.*$)/gm, '<h3>$1</h3>');
  text = text.replace(/^## (.*$)/gm, '<h2>$1</h2>');
  text = text.replace(/^# (.*$)/gm, '<h1>$1</h1>');

  // Handle bold, italic, and code formatting
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
  text = text.replace(/`(.*?)`/g, '<code>$1</code>');

  // Handle ordered lists - improved to handle consecutive items with proper numbering
  const orderedListPattern = /(?:^|\n)(\d+\.\s.+(?:\n\d+\.\s.+)*)/g;
  text = text.replace(orderedListPattern, function (match) {
    // Extract each list item
    const items = match
      .trim()
      .split(/\n/)
      .map(item => {
        return `<li>${item.replace(/^\d+\.\s/, '')}</li>`;
      });
    return `\n<ol>${items.join('')}</ol>\n`;
  });

  // Convert bullet lists
  let listItemsFound = true;
  while (listItemsFound) {
    const newText = text.replace(
      /(?:^|\n)(-\s.+)(?:\n(?!-\s))/g,
      function (match: string, listItem: string) {
        return `\n<ul><li>${listItem.replace(/^-\s/, '')}</li></ul>\n`;
      }
    );

    if (newText === text) {
      listItemsFound = false;
    } else {
      text = newText;
    }
  }

  // Convert multiple consecutive bullet list items
  text = text.replace(/(?:<\/ul>\n<ul>)/g, '');

  // Handle links
  text = text.replace(
    /\[([^\]]+)\]\(([^)]+)\)/g,
    '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
  );

  // Handle line breaks - replace with <br/> tag
  text = text.replace(/\n/g, '<br/>');

  return text;
};
