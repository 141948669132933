import { createRoot } from 'react-dom/client';
import AIChatWidget from './AIChatWidget';
import './AIChatWidget.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { createShadowRoot, isShadowDomSupported } from '../../lib/utils/shadowDomUtils';

// Ensure that React and ReactDOM are assigned to the global window object.
if (typeof window !== 'undefined') {
  window.React = React;
  window.ReactDOM = ReactDOM;
}

/**
 * Extended options interface for widget initialization
 * Includes all props from AIChatWidget plus additional configuration options
 */
interface AIChatWidgetOptions {
  botName?: string;
  botLogo?: string;
  primaryColor?: string;
  secondaryColor?: string;
  position?: 'left' | 'right';
  initialMessages?: string[];
}

interface AIChatWidgetOptionsWithAppIdAndToken extends AIChatWidgetOptions {
  appId: string;
  token: string;
}

const defaultOptions: Partial<AIChatWidgetOptions> = {
  botName: 'Support Agent',
  botLogo: undefined,
  primaryColor: '#007bff',
  position: 'right',
  initialMessages: [],
};

// Add cookie helper function
const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
};

const validateExistingToken = async (
  existingToken: string,
  baseUrl: string,
  appId: string
): Promise<boolean> => {
  try {
    const validateTokenApiUrl = `${baseUrl}/api/ai/validate-token`;
    const validationResponse = await fetch(validateTokenApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-App-Id': appId,
        Authorization: `Bearer ${existingToken}`,
      },
    });

    const validationData = await validationResponse.json();
    return validationResponse.ok && validationData.valid;
  } catch (validationError) {
    console.log('⚠️ Token validation failed:', validationError);
    return false;
  }
};

const fetchAndSetNewToken = async (baseUrl: string, appId: string): Promise<boolean> => {
  try {
    const tokenApiUrl = `${baseUrl}/api/ai/token`;
    const response = await fetch(tokenApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-App-Id': appId,
      },
    });

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    const data = await response.json();

    if (data.error) {
      console.error('Error getting token', data.error);
      return false;
    }

    if (data.token) {
      const cookieSettings = [
        `aiagent-token=${data.token}`,
        'path=/',
        'max-age=86400',
        'SameSite=Lax',
        process.env.NODE_ENV === 'production' ? 'Secure' : '',
      ].join('; ');

      document.cookie = cookieSettings;
      return true;
    }

    return false;
  } catch (error) {
    console.error('Error fetching new token:', error);
    return false;
  }
};

const fetchWidgetOptions = async (
  apiBaseUrl: string,
  appId: string
): Promise<AIChatWidgetOptions | null> => {
  const widgetOptionsApiUrl = `${apiBaseUrl}/api/ai/widget-options`;
  const existingToken = getCookie('aiagent-token');
  console.log('🔍 Fetching widget options from:', widgetOptionsApiUrl);

  try {
    const response = await fetch(widgetOptionsApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-App-Id': appId,
        Authorization: `Bearer ${existingToken}`,
      },
    });

    if (!response.ok) {
      console.error('Error fetching widget options:', response.statusText);
      return null;
    }

    const responseData = await response.json();
    console.log('📊 Widget options API response:', responseData);

    // Extract data from response, handling different possible structures
    let apiData = null;
    if (responseData.status === 200 && responseData.data) {
      apiData = responseData.data;
    } else if (responseData.data) {
      apiData = responseData.data;
    } else if (!responseData.error) {
      // If there's no error field, the response itself might be the data
      apiData = responseData;
    }

    if (apiData) {
      // Get initialMessages - handle different possible structures
      let initialMessagesFromAPI = [];

      if (Array.isArray(apiData.initialMessages)) {
        initialMessagesFromAPI = apiData.initialMessages;
      } else if (apiData.initialQuestions && Array.isArray(apiData.initialQuestions)) {
        // API might use 'initialQuestions' instead of 'initialMessages'
        initialMessagesFromAPI = apiData.initialQuestions;
      } else if (apiData.suggestedQuestions && Array.isArray(apiData.suggestedQuestions)) {
        // Or might use 'suggestedQuestions'
        initialMessagesFromAPI = apiData.suggestedQuestions;
      }

      console.log('💬 Initial messages from API:', initialMessagesFromAPI);

      const widgetOptions: AIChatWidgetOptions = {
        botName: (apiData.assistantName || apiData.botName || defaultOptions.botName) as string,
        botLogo: apiData.botLogo as string | undefined,
        primaryColor: (apiData.primaryColor || defaultOptions.primaryColor) as string,
        secondaryColor: (apiData.secondaryColor || defaultOptions.secondaryColor) as string,
        position: (apiData.position || defaultOptions.position) as 'left' | 'right',
        initialMessages:
          initialMessagesFromAPI.length > 0
            ? initialMessagesFromAPI
            : defaultOptions.initialMessages,
      };

      return widgetOptions;
    }

    if (responseData.error) {
      console.error('Error fetching widget options:', responseData.error);
    } else {
      console.error('Unexpected response format from widget options API');
    }

    return null;
  } catch (error) {
    console.error('Exception fetching widget options:', error);
    return null;
  }
};

const createWidget = async (
  apiBaseUrl: string,
  validatedOptions: AIChatWidgetOptionsWithAppIdAndToken
) => {
  try {
    // Clean up any existing widget
    const oldContainer = document.getElementById('ai-chat-widget-container');
    if (oldContainer) {
      oldContainer.remove();
    }

    // Create new container
    const widgetContainer = document.createElement('div');
    widgetContainer.id = 'ai-chat-widget-container';

    // Apply position styling to the container
    widgetContainer.style.position = 'fixed';
    widgetContainer.style.zIndex = '10000'; // Increase z-index for better stacking
    widgetContainer.style.bottom = '0';
    widgetContainer.style[validatedOptions.position || 'right'] = '0';
    widgetContainer.style.padding = '20px'; // Add padding to contain the chat button
    widgetContainer.style.boxSizing = 'border-box';
    widgetContainer.style.pointerEvents = 'none'; // Let clicks pass through container except for children

    // Check if Shadow DOM is supported
    const useShadowDom = isShadowDomSupported();
    let rootElement: HTMLElement;

    if (useShadowDom) {
      // Create Shadow DOM root for style isolation
      rootElement = createShadowRoot(widgetContainer);
      console.log('📍 Created widget container with Shadow DOM');
    } else {
      // Fallback for browsers that don't support Shadow DOM
      rootElement = widgetContainer;
      console.log('📍 Created widget container without Shadow DOM (not supported)');
    }

    // Add the container to the page
    document.body.appendChild(widgetContainer);

    // Create a React root and render the chat widget
    const root = createRoot(rootElement);

    // Fetch widget options from API
    console.log('🔄 Fetching options from API...');
    const widgetOptions = await fetchWidgetOptions(apiBaseUrl, validatedOptions.appId);

    // Merge API options with passed-in options
    console.log('🔄 Creating final widget options...');
    const finalWidgetOptions = {
      botName: widgetOptions?.botName || validatedOptions.botName,
      botLogo: widgetOptions?.botLogo || validatedOptions.botLogo,
      primaryColor: widgetOptions?.primaryColor || validatedOptions.primaryColor,
      secondaryColor: widgetOptions?.secondaryColor || validatedOptions.secondaryColor,
      position: widgetOptions?.position || validatedOptions.position,
      initialMessages: widgetOptions?.initialMessages || validatedOptions.initialMessages,
      appId: validatedOptions.appId,
      token: validatedOptions.token,
      apiBaseUrl: apiBaseUrl,
      useShadowDom: useShadowDom, // Pass flag to component that we're using Shadow DOM
    };

    // Log the final options
    console.log('✅ Final widget options:', {
      botName: finalWidgetOptions.botName,
      hasLogo: !!finalWidgetOptions.botLogo,
      primaryColor: finalWidgetOptions.primaryColor,
      secondaryColor: finalWidgetOptions.secondaryColor,
      position: finalWidgetOptions.position,
      initialMessagesCount: finalWidgetOptions.initialMessages?.length,
      appId: finalWidgetOptions.appId,
      hasToken: !!finalWidgetOptions.token,
      apiBaseUrl: finalWidgetOptions.apiBaseUrl,
      useShadowDom,
    });

    // Render the chat widget
    root.render(React.createElement(AIChatWidget, finalWidgetOptions));
    console.log('🎨 Widget rendered successfully');
  } catch (error) {
    console.error('Error creating widget:', error);
  }
};

// Update the initWhenReady function
const initWhenReady = (
  apiBaseUrl: string,
  validatedOptions: AIChatWidgetOptionsWithAppIdAndToken
) => {
  return new Promise<void>(resolve => {
    const attemptCreation = () => {
      if (document.body) {
        createWidget(apiBaseUrl, validatedOptions);
        resolve();
      } else {
        requestAnimationFrame(attemptCreation);
      }
    };

    if (document.readyState === 'complete') {
      attemptCreation();
    } else {
      window.addEventListener('load', attemptCreation);
    }
  });
};

/**
 * Handles the token validation and initialization flow
 * 1. Validates existing token if present
 * 2. Fetches new token if needed
 * 3. Initializes the widget
 */
const handleTokenAndInitialization = async (
  apiBaseUrl: string,
  validatedOptions: AIChatWidgetOptionsWithAppIdAndToken
): Promise<void | null> => {
  try {
    // Step 1: Check for existing valid token
    const existingToken = getCookie('aiagent-token');
    if (existingToken) {
      const isExistingTokenValid = await validateExistingToken(
        existingToken,
        apiBaseUrl,
        validatedOptions.appId
      );

      if (isExistingTokenValid) {
        // Step 3a: Initialize widget with existing valid token
        return initWhenReady(apiBaseUrl, validatedOptions);
      }
    }

    // Step 2: Get new token if no valid token exists
    const tokenSet = await fetchAndSetNewToken(apiBaseUrl, validatedOptions.appId);
    if (!tokenSet) {
      return null;
    }

    // Step 3b: Initialize widget with new token
    return initWhenReady(apiBaseUrl, validatedOptions);
  } catch (error) {
    console.error('Initialization error:', error);
    return null;
  }
};

/**
 * Initializes and mounts the AI Chat Widget into the webpage
 * This function is exposed globally and can be called from any webpage
 *
 * @param options - Configuration options for the chat widget
 * @example
 * window.AIChatWidget.initAIChatWidget({
 *   botName: 'Support Bot',
 *   primaryColor: '#007bff',
 *   position: 'right',
 *   appId: 'your-app-id'
 * });
 */
export async function initAIChatWidget(
  options: Partial<AIChatWidgetOptionsWithAppIdAndToken> = {}
) {
  const validatedOptions = {
    ...defaultOptions,
    ...options,
  };

  if (!validatedOptions.appId) {
    throw new Error('appId is required');
  }

  // Extract token from script URL if not provided in options
  if (!validatedOptions.token) {
    const scripts = document.getElementsByTagName('script');
    const currentScript = scripts[scripts.length - 1];
    const urlParams = new URLSearchParams(currentScript.src.split('?')[1]);
    validatedOptions.token = urlParams.get('token') || '';
  }

  // Add domain validation
  // TODO: We need to validate the currentDomain against the registered domains of the client/customer
  // What we can do is that before we call initAIChatWidget() or before completing initaliztion, we can call an api endpoint passing appId and with referer information, the backend api can validate if that will match.
  // const currentDomain = new URL(window.location.href).hostname;
  // console.log(
  //   `initAIChatWidget() currentDomain: ${currentDomain}, options.domain: ${options.domain}`
  // );

  const baseUrl = import.meta.env.VITE_API_BASE_URL.replace(/\/+$/, '');
  // Start the initialization flow
  return handleTokenAndInitialization(
    baseUrl,
    validatedOptions as AIChatWidgetOptionsWithAppIdAndToken
  );
}

/**
 * Global type declaration for the window object
 * Ensures TypeScript recognizes the AIChatWidget global variable
 */
declare global {
  interface Window {
    AIChatWidget: {
      initAIChatWidget: typeof initAIChatWidget;
    };
  }
}

/**
 * Expose the initialization function globally
 * This makes it accessible via window.AIChatWidget.initAIChatWidget
 */
window.AIChatWidget = {
  initAIChatWidget,
};

/**
 * Dispatch a custom event when the widget is ready
 * This allows websites to listen for widget initialization
 * @example
 * document.addEventListener('AIChatWidgetReady', () => {
 *   // Initialize widget here
 * });
 */
document.dispatchEvent(new Event('AIChatWidgetReady'));
